import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { firestore } from '../firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Input } from './ui/input';
import { Button } from './ui/button';

const CreateBoard = () => {
  const [boardName, setBoardName] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { user, addBoardToUser } = useAuth();

  const handleCreateBoard = async (e) => {
    e.preventDefault();
    if (!user) {
      setError('You must be logged in to create a board');
      return;
    }

    try {
      // Create new board document
      const boardRef = await addDoc(collection(firestore, 'boards'), {
        name: boardName,
        createdBy: user.uid,
        createdAt: serverTimestamp(),
        admins: [user.uid],
        members: [user.uid],
        settings: {
          tags: [],
          statuses: ['Submitted', 'In Progress', 'Completed']
        },
        isDemo: false  // Add this line
      });

      // Add board to user's boards
      await addBoardToUser(boardRef.id);

      // Navigate to the new board
      navigate(`/board/${boardRef.id}`);
    } catch (error) {
      console.error('Error creating board:', error);
      setError('Failed to create board. Please try again.');
    }
  };

  return (
    <div className="container mx-auto mt-10">
      <Card className="max-w-md mx-auto">
        <CardHeader>
          <CardTitle>Create New Board</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleCreateBoard}>
            <div className="space-y-4">
              <Input
                id="boardName"
                placeholder="Enter board name"
                value={boardName}
                onChange={(e) => setBoardName(e.target.value)}
                required
              />
            </div>
            {error && <p className="text-red-500 mt-2">{error}</p>}
            <Button type="submit" className="mt-4">Create Board</Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

export default CreateBoard;