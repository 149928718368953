import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { auth, firestore } from './firebase';
import { onAuthStateChanged, signOut as firebaseSignOut } from 'firebase/auth';
import { doc, getDoc, updateDoc, arrayUnion, setDoc, serverTimestamp } from 'firebase/firestore';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentBoardId, setCurrentBoardId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDemoBoard, setIsDemoBoard] = useState(false);

  const fetchUserData = useCallback(async (firebaseUser) => {
    if (!firebaseUser) return null;
  
    const userDocRef = doc(firestore, 'users', firebaseUser.uid);
    try {
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        return {
          ...firebaseUser,
          isAdmin: userData.isAdmin === true,
          boards: userData.boards || [],
        };
      } else {
        return { ...firebaseUser, isAdmin: false, boards: [] };
      }
    } catch (error) {
      console.error("Error fetching user document:", error);
      return { ...firebaseUser, isAdmin: false, boards: [] };
    }
  }, []);

  const refreshUserData = useCallback(async () => {
    if (auth.currentUser) {
      const updatedUser = await fetchUserData(auth.currentUser);
      setUser(updatedUser);
    }
  }, [fetchUserData]);

  const addBoardToUser = useCallback(async (boardId) => {
    if (!user) return;

    const userDocRef = doc(firestore, 'users', user.uid);
    try {
      // Try to get the existing user document
      const userDoc = await getDoc(userDocRef);
      
      if (userDoc.exists()) {
        // If the user document exists, update it
        await updateDoc(userDocRef, {
          boards: arrayUnion(boardId)
        });
      } else {
        // If the user document doesn't exist, create it
        await setDoc(userDocRef, {
          email: user.email,
          boards: [boardId],
          createdAt: serverTimestamp()
        });
      }
      
      console.log(`Board ${boardId} added to user ${user.uid}`);
      await refreshUserData();
    } catch (error) {
      console.error("Error adding board to user:", error);
      throw error;
    }
  }, [user, refreshUserData]);

  const isInvitedAdmin = useCallback(async (boardId) => {
    if (!user) return false;

    const boardRef = doc(firestore, 'boards', boardId);
    const boardDoc = await getDoc(boardRef);

    if (boardDoc.exists()) {
      const boardData = boardDoc.data();
      return boardData.invitedAdmins && boardData.invitedAdmins.includes(user.email);
    }

    return false;
  }, [user]);

  const checkIfDemoBoard = useCallback(async (boardId) => {
    if (!boardId) return false;
    const boardRef = doc(firestore, 'boards', boardId);
    const boardDoc = await getDoc(boardRef);
    if (boardDoc.exists()) {
      const boardData = boardDoc.data();
      return boardData.isDemo === true;
    }
    return false;
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      console.log("Auth state changed. User:", firebaseUser);
      if (firebaseUser) {
        const userData = await fetchUserData(firebaseUser);
        setUser(userData);
        const userDoc = await getDoc(doc(firestore, 'users', firebaseUser.uid));
        const userDocData = userDoc.data();
        setIsAdmin(userDocData?.isAdmin || false);
      } else {
        setUser(null);
        setIsAdmin(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [fetchUserData]);

  useEffect(() => {
    console.log('Is admin:', isAdmin);
  }, [isAdmin]);

  const signOut = useCallback(async () => {
    try {
      await firebaseSignOut(auth);
      // Force an immediate state update
      setUser(null);
      setLoading(false);
      setIsAdmin(false);
      console.log("User signed out successfully");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  }, []);

  const value = {
    user,
    loading,
    signOut,
    refreshUserData,
    isAdmin,
    currentBoardId,
    setCurrentBoardId,
    addBoardToUser,
    isInvitedAdmin,
    isDemoBoard,
    setIsDemoBoard,
    checkIfDemoBoard,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};