import React, { createContext, useState, useContext, useEffect } from 'react';
import { firestore } from './firebase';
import { doc, getDoc } from 'firebase/firestore';

const ThemeContext = createContext();

export const themes = {
  light: {
    primary: '#3b82f6',
    secondary: '#10b981',
    background: '#ffffff',
    text: '#1f2937',
    cardBackground: '#f3f4f6',
    borderColor: '#e5e7eb',
    buttonHover: '#2563eb',
    inputBackground: '#f9fafb',
  },
  dark: {
    primary: '#60a5fa',
    secondary: '#34d399',
    background: '#1f2937',
    text: '#f3f4f6',
    cardBackground: '#374151',
    borderColor: '#4b5563',
    buttonHover: '#3b82f6',
    inputBackground: '#374151',
  },
};

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(themes.light);
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const fetchTheme = async () => {
      const settingsDoc = await getDoc(doc(firestore, 'settings', 'general'));
      if (settingsDoc.exists()) {
        const data = settingsDoc.data();
        if (data.theme) {
          setTheme(data.theme === 'dark' ? themes.dark : themes.light);
          setIsDarkMode(data.theme === 'dark');
        }
      }
    };
    fetchTheme();
  }, []);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    setTheme(isDarkMode ? themes.light : themes.dark);
    // You might want to save the theme preference to Firestore here
  };

  return (
    <ThemeContext.Provider value={{ theme, isDarkMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);