import React, { useEffect, useRef, useMemo } from 'react';
import { auth } from '../../firebase';
import * as firebaseui from 'firebaseui';
import firebase from 'firebase/compat/app';
import 'firebaseui/dist/firebaseui.css';

const AuthUI = ({ onClose }) => {
  const uiConfig = useMemo(() => ({
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => {
        onClose();
        return false;
      },
    },
    signInFlow: 'popup'
  }), [onClose]);

  const uiRef = useRef(null);

  useEffect(() => {
    let ui = firebaseui.auth.AuthUI.getInstance();
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(auth);
    }
    uiRef.current = ui;

    ui.start('#firebaseui-auth-container', uiConfig);

    return () => {
      ui.reset();
    };
  }, [uiConfig]);

  return <div id="firebaseui-auth-container"></div>;
};

export default AuthUI;