import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { firestore } from '../firebase';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { Button } from "./ui/button"
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "./ui/select"
import { useBoard } from '../BoardContext';

const NavBar = ({ onLoginClick, showSubmitForm, setShowSubmitForm, isLandingPage }) => {
  const { user, isAdmin, signOut } = useAuth();
  const navigate = useNavigate();
  const { board, boardId, setBoardId } = useBoard();
  const { boardId: urlBoardId } = useParams();
  const [siteName, setSiteName] = useState('Feedback Board');
  const [pendingInvitations, setPendingInvitations] = useState([]);

  useEffect(() => {
    if (urlBoardId) {
      setBoardId(urlBoardId);
    }
  }, [urlBoardId, setBoardId]);

  useEffect(() => {
    console.log('NavBar - Current board:', board);
  }, [board]);

  useEffect(() => {
    const fetchSiteName = async () => {
      const settingsDoc = await getDoc(doc(firestore, 'settings', 'general'));
      if (settingsDoc.exists()) {
        setSiteName(settingsDoc.data().siteName || 'Feedback Board');
      }
    };
    fetchSiteName();
  }, []);

  useEffect(() => {
    const fetchPendingInvitations = async () => {
      if (user) {
        const invitationsQuery = query(
          collection(firestore, 'boards'),
          where('invitedAdmins', 'array-contains', user.email)
        );
        const invitationsSnapshot = await getDocs(invitationsQuery);
        const invitations = invitationsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPendingInvitations(invitations);
      }
    };
    fetchPendingInvitations();
  }, [user]);

  const handleSignOut = async () => {
    await signOut();
    navigate('/');
  };
  
  const handleAdminSettings = () => {
    console.log('handleAdminSettings called', { board, user, boardId });
    if (boardId) {
      navigate(`/admin-settings/${boardId}`);
    } else {
      console.error('No board selected, boardId is undefined');
      alert('Unable to access board settings. Please try again later.');
    }
  };

  const handleViewDemo = () => {
    navigate('/board/demo');
  };

  const handleCreateBoard = () => {
    navigate('/create-board');
  };

  return (
    <nav className="bg-gray-900 shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/" className="text-xl font-bold text-white">{siteName}</Link>
            </div>
          </div>
          <div className="flex items-center">
            {isLandingPage ? (
              <>
                <Button onClick={handleViewDemo} variant="ghost" className="text-gray-100 hover:text-blue-400 transition-colors mr-4">View Demo Board</Button>
                <Button onClick={handleCreateBoard} className="bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white transition-all duration-300 ease-in-out transform hover:scale-105">
                  Create Board
                </Button>
              </>
            ) : (
              <>
                <Button onClick={() => setShowSubmitForm(true)} className="mr-4">Submit Feedback</Button>
                {user ? (
                  <>
                    {(isAdmin || (board && user.uid === board.createdBy)) && boardId && (
                      <Button onClick={handleAdminSettings} className="ml-4">Board Settings</Button>
                    )}
                    {pendingInvitations.length > 0 && (
                      <div className="ml-4">
                        <Select onValueChange={(boardId) => navigate(`/board/${boardId}/invite/${user.uid}`)}>
                          <SelectTrigger className="w-[200px]">
                            <SelectValue placeholder={`${pendingInvitations.length} Pending Invitation${pendingInvitations.length > 1 ? 's' : ''}`} />
                          </SelectTrigger>
                          <SelectContent>
                            {pendingInvitations.map(board => (
                              <SelectItem key={board.id} value={board.id}>
                                Accept invite to {board.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                    )}
                    <Button onClick={handleSignOut} className="ml-4">Sign Out</Button>
                  </>
                ) : (
                  <Button onClick={onLoginClick}>Sign In</Button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;