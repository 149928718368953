import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { firestore } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';

const BoardContext = createContext();

export const BoardProvider = ({ children }) => {
  const { boardId: urlBoardId } = useParams();
  const [board, setBoard] = useState(null);
  const [boardId, setBoardId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (urlBoardId) {
      setBoardId(urlBoardId);
    }
  }, [urlBoardId]);

  useEffect(() => {
    const fetchBoard = async () => {
      if (boardId) {
        setLoading(true);
        try {
          const boardDoc = await getDoc(doc(firestore, 'boards', boardId));
          if (boardDoc.exists()) {
            setBoard({ id: boardId, ...boardDoc.data() });
          } else {
            setBoard(null);
          }
        } catch (error) {
          console.error("Error fetching board:", error);
          setBoard(null);
        }
        setLoading(false);
      } else {
        setBoard(null);
        setLoading(false);
      }
    };

    fetchBoard();
  }, [boardId]);

  const contextValue = useMemo(() => ({
    board,
    setBoard,
    boardId,
    setBoardId,
    loading
  }), [board, boardId, loading]);

  return (
    <BoardContext.Provider value={contextValue}>
      {children}
    </BoardContext.Provider>
  );
};

export const useBoard = () => useContext(BoardContext);