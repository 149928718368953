import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { firestore } from '../firebase';
import { doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';
import { HexColorPicker } from "react-colorful";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { Button } from "../components/ui/button"

const AdminSettings = () => {
  const { user, isAdmin } = useAuth();
  const { boardId } = useParams();
  const navigate = useNavigate();
  const [board, setBoard] = useState(null);
  const [tags, setTags] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [newStatus, setNewStatus] = useState('');
  const [boardName, setBoardName] = useState('');
  const [boardDescription, setBoardDescription] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [primaryColor, setPrimaryColor] = useState('#000000');
  const [secondaryColor, setSecondaryColor] = useState('#000000');
  const [inviteEmail, setInviteEmail] = useState('');
  const [isDemoBoard, setIsDemoBoard] = useState(false);

  useEffect(() => {
    const fetchBoard = async () => {
      try {
        const boardDoc = await getDoc(doc(firestore, 'boards', boardId));
        if (boardDoc.exists()) {
          const boardData = boardDoc.data();
          setBoard(boardData);
          setTags(boardData.settings?.tags || []);
          setStatuses(boardData.settings?.statuses || []);
          setBoardName(boardData.name || 'Feedback Board');
          setBoardDescription(boardData.description || '');
          setPrimaryColor(boardData.theme?.primary || '#000000');
          setSecondaryColor(boardData.theme?.secondary || '#000000');
          setIsDemoBoard(boardData.isDemo === true);
        } else {
          setError("Board not found");
        }
      } catch (err) {
        setError("Failed to load board. Please try again.");
        console.error("Error fetching board:", err);
      }
      setLoading(false);
    };

    fetchBoard();
  }, [boardId]);

  const canEditBoard = isAdmin || (board && board.createdBy === user?.uid);

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!canEditBoard) return <div>You don't have permission to access this page.</div>;

  const updateSettings = async () => {
    if (!canEditBoard) return;

    try {
      const settingsDocRef = doc(firestore, 'boards', boardId);
      
      const uniqueStatuses = ["Submitted", ...new Set(statuses.filter(status => status !== "Submitted"))];
      
      await updateDoc(settingsDocRef, {
        name: boardName,
        description: boardDescription,
        settings: {
          tags,
          statuses: uniqueStatuses
        },
        theme: {
          primary: primaryColor,
          secondary: secondaryColor
        }
      });
      console.log('Settings updated successfully:', { boardName, boardDescription, tags, statuses: uniqueStatuses });
      alert('Settings updated successfully!');
    } catch (err) {
      console.error("Error updating settings:", err);
      alert("Failed to update settings. Please try again.");
    }
  };

  const addTag = () => {
    if (newTag && !tags.includes(newTag)) {
      setTags([...tags, newTag]);
      setNewTag('');
    }
  };

  const addStatus = () => {
    if (newStatus && !statuses.includes(newStatus) && newStatus !== "Submitted") {
      setStatuses([...new Set(["Submitted", ...statuses, newStatus])]);
      setNewStatus('');
    }
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  const removeStatus = (statusToRemove) => {
    setStatuses(statuses.filter(status => status !== statusToRemove));
  };

  const handleInviteAdmin = async (e) => {
    e.preventDefault();
    if (!canEditBoard) {
      setError('Only admins or board creators can invite other admins');
      return;
    }
    try {
      const boardRef = doc(firestore, 'boards', boardId);
      await updateDoc(boardRef, {
        invitedAdmins: arrayUnion(inviteEmail)
      });
      setInviteEmail('');
      setError(null);
    } catch (error) {
      console.error("Error inviting admin: ", error);
      setError("Failed to invite admin. Please try again.");
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Board Settings</h1>
        <Button onClick={() => navigate(`/board/${boardId}`)} variant="outline">
          Back to Board
        </Button>
      </div>

      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Board Name</h2>
        <input
          type="text"
          value={boardName}
          onChange={(e) => setBoardName(e.target.value)}
          className="border p-2 rounded w-full"
        />
      </div>

      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Board Description</h2>
        <textarea
          value={boardDescription}
          onChange={(e) => setBoardDescription(e.target.value)}
          className="border p-2 rounded w-full"
          rows="3"
        />
      </div>

      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Tags</h2>
        <div className="flex space-x-2 mb-2">
          <input
            type="text"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            className="border p-2 rounded flex-grow"
            placeholder="New tag"
          />
          <button onClick={addTag} className="bg-blue-500 text-white px-4 py-2 rounded">Add Tag</button>
        </div>
        <div className="flex flex-wrap gap-2">
          {tags.map(tag => (
            <div key={tag} className="bg-gray-200 px-2 py-1 rounded flex items-center">
              {tag}
              <button onClick={() => removeTag(tag)} className="ml-2 text-red-500">&times;</button>
            </div>
          ))}
        </div>
      </div>

      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Statuses</h2>
        <div className="flex space-x-2 mb-2">
          <input
            type="text"
            value={newStatus}
            onChange={(e) => setNewStatus(e.target.value)}
            className="border p-2 rounded flex-grow"
            placeholder="New status"
          />
          <button onClick={addStatus} className="bg-blue-500 text-white px-4 py-2 rounded">Add Status</button>
        </div>
        <div className="flex flex-wrap gap-2">
          {statuses.map(status => (
            <div key={status} className="bg-gray-200 px-2 py-1 rounded flex items-center">
              {status}
              <button onClick={() => removeStatus(status)} className="ml-2 text-red-500">&times;</button>
            </div>
          ))}
        </div>
      </div>

      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Theme Colors</h2>
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex-1">
            <Label htmlFor="primaryColor">Primary Color</Label>
            <div className="mt-2 flex items-center space-x-2">
              <HexColorPicker color={primaryColor} onChange={setPrimaryColor} />
              <div
                className="w-10 h-10 border border-gray-300 rounded"
                style={{ backgroundColor: primaryColor }}
              ></div>
            </div>
            <Input
              id="primaryColor"
              value={primaryColor}
              onChange={(e) => setPrimaryColor(e.target.value)}
              className="mt-2"
            />
          </div>
          <div className="flex-1">
            <Label htmlFor="secondaryColor">Secondary Color</Label>
            <div className="mt-2 flex items-center space-x-2">
              <HexColorPicker color={secondaryColor} onChange={setSecondaryColor} />
              <div
                className="w-10 h-10 border border-gray-300 rounded"
                style={{ backgroundColor: secondaryColor }}
              ></div>
            </div>
            <Input
              id="secondaryColor"
              value={secondaryColor}
              onChange={(e) => setSecondaryColor(e.target.value)}
              className="mt-2"
            />
          </div>
        </div>
      </div>

      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Invited Admins</h2>
        <ul className="list-disc pl-5">
          {board.invitedAdmins && board.invitedAdmins.map((email) => (
            <li key={email}>{email}</li>
          ))}
        </ul>
        <form onSubmit={handleInviteAdmin} className="flex items-center space-x-2 mt-4">
          <Input
            type="email"
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
            placeholder="Enter email to invite admin"
            required
          />
          <Button type="submit">Invite Admin</Button>
        </form>
      </div>

      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Demo Board Status</h2>
        <p>{isDemoBoard ? "This is a demo board" : "This is not a demo board"}</p>
      </div>

      <button onClick={updateSettings} className="bg-green-500 text-white px-4 py-2 rounded">Save Settings</button>
    </div>
  );
};

export default AdminSettings;