import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from './ui/button';
import { FaComments, FaChartLine, FaPiggyBank, FaClock, FaCheck, FaRobot } from 'react-icons/fa';

const LandingPage = () => {
  const navigate = useNavigate();

  const handleViewDemo = () => {
    navigate('/board/demo');
  };

  const handleCreateBoard = () => {
    navigate('/create-board');
  };

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      <main className="container mx-auto px-4 py-16">
        <section className="text-center mb-16">
          <h1 className="text-5xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500">
            AI-Powered Feedback Management
          </h1>
          <p className="text-xl mb-8 text-gray-400">
            Streamline your product development with intelligent feedback analysis and prioritization
          </p>
          <div className="space-x-4">
            <Button onClick={handleViewDemo} className="bg-blue-500 hover:bg-blue-600 text-white transition-all duration-300 ease-in-out transform hover:scale-105">
              View Demo Board
            </Button>
            <Button onClick={handleCreateBoard} className="bg-transparent border border-blue-400 text-blue-400 hover:bg-blue-400/10 transition-all duration-300 ease-in-out">
              Create a New Board
            </Button>
          </div>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-4 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
            Why Choose Our AI-Enhanced Feedback Board?
          </h2>
          <p className="text-lg mb-8 text-center text-gray-400">
            Our AI-powered system revolutionizes feedback management, leading to:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              { icon: FaRobot, title: "Smart Categorization", description: "AI automatically categorizes feedback", color: "text-blue-400" },
              { icon: FaChartLine, title: "Intelligent Prioritization", description: "AI suggests priority based on impact", color: "text-purple-400" },
              { icon: FaClock, title: "Time Savings", description: "Reduce manual sorting and analysis", color: "text-pink-400" },
              { icon: FaPiggyBank, title: "Improved Efficiency", description: "Focus on high-impact features faster", color: "text-green-400" },
            ].map((feature, index) => (
              <div key={index} className="flex flex-col items-center text-center space-y-4 p-6 bg-gray-800 rounded-lg transition-all duration-300 hover:bg-gray-700 hover:shadow-lg hover:shadow-blue-500/20">
                <feature.icon className={`h-12 w-12 ${feature.color}`} />
                <h3 className="text-xl font-bold">{feature.title}</h3>
                <p className="text-gray-400">{feature.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section>
          <h2 className="text-3xl font-bold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
            Where Customers Give Feedback
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-3xl mx-auto">
            {[
              { icon: FaCheck, text: "Feedback board", active: true },
              { icon: FaClock, text: "App store and Play store reviews" },
              { icon: FaClock, text: "Social media channels" },
              { icon: FaClock, text: "Support channels" },
              { icon: FaClock, text: "User interviews and surveys" },
            ].map((item, index) => (
              <div key={index} className="flex items-center space-x-4 bg-gray-700 p-4 rounded-lg transition-all duration-300 hover:bg-gray-600">
                <div className={`p-2 rounded-full ${item.active ? 'bg-blue-500' : 'bg-gray-500'}`}>
                  <item.icon className="h-6 w-6 text-white" />
                </div>
                <span className={item.active ? 'text-white' : 'text-gray-400'}>{item.text}</span>
              </div>
            ))}
          </div>
        </section>

        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-8 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
            How Our AI Works
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-4xl mx-auto">
            {[
              { icon: FaComments, text: "Analyze feedback content" },
              { icon: FaRobot, text: "Categorize automatically" },
              { icon: FaChartLine, text: "Suggest priority levels" },
            ].map((item, index) => (
              <div key={index} className="flex flex-col items-center space-y-4 bg-gray-700 p-6 rounded-lg transition-all duration-300 hover:bg-gray-600">
                <div className="p-3 rounded-full bg-blue-500">
                  <item.icon className="h-8 w-8 text-white" />
                </div>
                <span className="text-center text-white">{item.text}</span>
              </div>
            ))}
          </div>
        </section>
      </main>

      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t border-gray-700">
        <p className="text-xs text-gray-400">© 2024 Akshay's Feedback Board. All rights reserved.</p>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          <a 
            href="/terms" 
            className="text-xs hover:underline underline-offset-4 text-gray-400 hover:text-gray-100 transition-colors"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
          <a 
            href="/privacy" 
            className="text-xs hover:underline underline-offset-4 text-gray-400 hover:text-gray-100 transition-colors"
            rel="noopener noreferrer"
          >
            Privacy
          </a>
        </nav>
      </footer>
    </div>
  );
};

export default LandingPage;