import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { firestore } from '../firebase';
import { doc, getDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { useAuth } from '../AuthContext';
import { Button } from './ui/button';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';

const InvitationAccept = () => {
  const { boardId } = useParams();
  const { user, addBoardToUser } = useAuth();
  const navigate = useNavigate();
  const [board, setBoard] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBoard = async () => {
      if (!boardId || !user) return;

      try {
        const boardDoc = await getDoc(doc(firestore, 'boards', boardId));
        if (boardDoc.exists()) {
          setBoard(boardDoc.data());
        } else {
          setError('Board not found');
        }
      } catch (err) {
        console.error('Error fetching board:', err);
        setError('Failed to load board information');
      }
    };

    fetchBoard();
  }, [boardId, user]);

  const handleAcceptInvitation = async () => {
    if (!user || !board) return;

    try {
      const boardRef = doc(firestore, 'boards', boardId);
      
      // First, check if the user is actually invited
      const boardSnapshot = await getDoc(boardRef);
      const boardData = boardSnapshot.data();
      
      if (!boardData.invitedAdmins || !boardData.invitedAdmins.includes(user.email)) {
        setError('You are not invited to this board');
        return;
      }

      // If the user is invited, proceed with the update
      await updateDoc(boardRef, {
        admins: arrayUnion(user.uid),
        members: arrayUnion(user.uid),
        invitedAdmins: arrayRemove(user.email)
      });
      
      // Add the board to the user's boards list
      await addBoardToUser(boardId);
      
      navigate(`/board/${boardId}`);
    } catch (err) {
      console.error('Error accepting invitation:', err);
      setError('Failed to accept invitation: ' + err.message);
    }
  };

  if (error) return <div className="text-red-500">{error}</div>;
  if (!board) return <div>Loading...</div>;

  return (
    <div className="container mx-auto mt-10">
      <Card className="max-w-md mx-auto">
        <CardHeader>
          <CardTitle>Accept Admin Invitation</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="mb-4">You've been invited to become an admin for the board: {board.name}</p>
          <Button onClick={handleAcceptInvitation}>Accept Invitation</Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default InvitationAccept;