// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserSessionPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import {getStorage} from 'firebase/storage'


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCvHZLKohI8UY0JDsM7vbyFVvvki6oZc_g",
  authDomain: "canny-killer.firebaseapp.com",
  projectId: "canny-killer",
  storageBucket: "canny-killer.appspot.com",
  messagingSenderId: "214293136647",
  appId: "1:214293136647:web:61a5e1f738bd900d92b1a2",
  measurementId: "G-RK0Z13RCR3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
setPersistence(auth, browserSessionPersistence);

// Initialize Cloud Firestore and get a reference to the service
export const firestore = getFirestore(app);
export default app;
export const storage = getStorage(app);