import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { BoardProvider } from './BoardContext';
import NavBar from './components/NavBar';
import FeedbackBoard from './components/FeedbackBoard';
import CreateBoard from './components/CreateBoard';
import AuthUI from './components/auth/AuthUI';
import LandingPage from './components/LandingPage.js';
import InvitationAccept from './components/InvitationAccept';
import JoinBoard from './components/JoinBoard';
import AdminSettings from './components/AdminSettings';

function App() {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSubmitForm, setShowSubmitForm] = useState(false);
  const { user, loading } = useAuth();

  useEffect(() => {
    if (user) {
      setShowLoginModal(false);
    }
  }, [user]);

  const handleCloseModal = () => {
    setShowLoginModal(false);
  };

  return (
    <Router>
      <BoardProvider>
        <NavBar 
          onLoginClick={() => setShowLoginModal(true)}
          showSubmitForm={showSubmitForm}
          setShowSubmitForm={setShowSubmitForm}
          isLandingPage={window.location.pathname === '/'}
        />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/create-board" element={<CreateBoard />} />
          <Route 
            path="/board/demo" 
            element={
              <FeedbackBoard 
                onLoginRequired={() => setShowLoginModal(true)} 
                showSubmitForm={showSubmitForm} 
                setShowSubmitForm={setShowSubmitForm}
                isDemoBoard={true}
              />
            } 
          />
          <Route 
            path="/board/:boardId" 
            element={
              <FeedbackBoard 
                onLoginRequired={() => setShowLoginModal(true)} 
                showSubmitForm={showSubmitForm} 
                setShowSubmitForm={setShowSubmitForm}
              />
            } 
          />
          <Route path="/board/:boardId/invite/:inviteId" element={<InvitationAccept />} />
          <Route path="/join-board" element={<JoinBoard />} />
          <Route path="/admin-settings/:boardId" element={
            user ? <AdminSettings /> : <Navigate to="/" replace />
          } />
        </Routes>
        {showLoginModal && !user && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center" onClick={handleCloseModal}>
            <div className="bg-white p-8 rounded-lg" onClick={(e) => e.stopPropagation()}>
              <AuthUI onClose={handleCloseModal} />
            </div>
          </div>
        )}
      </BoardProvider>
    </Router>
  );
}

export default App;