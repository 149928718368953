import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { useBoard } from '../BoardContext';
import { firestore, storage } from '../firebase';
import { doc, getDoc, addDoc, updateDoc, arrayRemove, arrayUnion, collection, query, orderBy, onSnapshot, runTransaction, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button } from "../components/ui/button"
import { Card, CardTitle, CardDescription } from "../components/ui/card"
import { Input } from "../components/ui/input"
import { Textarea } from "../components/ui/textarea"
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "../components/ui/select"
import { Badge } from "../components/ui/badge"
import { format } from 'date-fns';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../components/ui/dialog"

function FeedbackBoard({ onLoginRequired, showSubmitForm, setShowSubmitForm, pendingAction, isDemoBoard }) {
  const { boardId } = useParams();
  const { user, isAdmin } = useAuth();
  const { board, setBoard, loading, setBoardId } = useBoard();
  const theme = useMemo(() => ({
    primary: board?.theme?.primary || '#000000',
    secondary: board?.theme?.secondary || '#000000'
  }), [board]);
  const [feedbackItems, setFeedbackItems] = useState([]);
  const [localFeedbackItems, setLocalFeedbackItems] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [image, setImage] = useState(null);
  const [sortBy, setSortBy] = useState('date');
  const [filterStatus, setFilterStatus] = useState('All');
  const [feedbackByStatus, setFeedbackByStatus] = useState({});
  const [tags, setTags] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [error, setError] = useState(null);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('Submitted');
  const [showEditForm, setShowEditForm] = useState(false);

  useEffect(() => {
    if (boardId) {
      console.log("Setting boardId:", boardId);
      setBoardId(boardId);
    }
  }, [boardId, setBoardId]);

  useEffect(() => {
    console.log("Current boardId:", boardId);
    console.log("Current board:", board);
    console.log("Current URL:", window.location.href);
  }, [boardId, board]);

  useEffect(() => {
    const fetchBoardData = async () => {
      if (!boardId) return;

      try {
        const boardDoc = await getDoc(doc(firestore, 'boards', boardId));
        if (boardDoc.exists()) {
          const boardData = boardDoc.data();
          setBoard({ id: boardDoc.id, ...boardData });
          setTags(boardData.settings?.tags || []);
          const boardStatuses = boardData.settings?.statuses || [];
          const allStatuses = ["Submitted", ...new Set(boardStatuses.filter(status => status !== "Submitted"))];
          setStatuses(allStatuses);
        } else {
          setError('Board not found');
        }
      } catch (error) {
        console.error("Error fetching board data:", error);
        setError('Failed to load board data');
      }
    };

    fetchBoardData();
  }, [boardId, setBoard]);

  useEffect(() => {
    if (!boardId) return;

    const feedbackCollection = collection(firestore, 'boards', boardId, 'feedback');
    const q = query(feedbackCollection, orderBy('createdAt', 'desc'));
    
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const items = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        hasUpvoted: doc.data().upvotedBy?.includes(user?.uid)
      }));
      
      if (isDemoBoard && !isAdmin) {
        // For demo board and non-admin users, merge new items with existing local items
        setLocalFeedbackItems(prevItems => {
          const newItemIds = new Set(items.map(item => item.id));
          const filteredPrevItems = prevItems.filter(item => !newItemIds.has(item.id));
          return [...filteredPrevItems, ...items];
        });
      } else {
        setFeedbackItems(items);
      }
    });

    return () => unsubscribe();
  }, [boardId, user?.uid, isDemoBoard, isAdmin]);

  useEffect(() => {
    const itemsToGroup = isDemoBoard ? localFeedbackItems : feedbackItems;
    const groupedFeedback = itemsToGroup.reduce((acc, item) => {
      const status = item.status || 'Submitted';
      if (!acc[status]) {
        acc[status] = [];
      }
      acc[status].push(item);
      return acc;
    }, {});
    
    // Ensure all statuses are present, including 'Submitted'
    const allStatuses = ['Submitted', ...statuses.filter(status => status !== 'Submitted')];
    allStatuses.forEach(status => {
      if (!groupedFeedback[status]) {
        groupedFeedback[status] = [];
      }
    });
  
    console.log('Grouped feedback:', groupedFeedback);
    console.log('All statuses used for grouping:', allStatuses);
    console.log('Final grouped feedback:', groupedFeedback);
    setFeedbackByStatus(groupedFeedback);
  }, [feedbackItems, localFeedbackItems, statuses, isDemoBoard]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    try {
      console.log('Submitting feedback:', { title, description, selectedTags, boardId, user });
      let imageUrl = null;
      if (image) {
        const storageRef = ref(storage, `images/${image.name}`);
        await uploadBytes(storageRef, image);
        imageUrl = await getDownloadURL(storageRef);
      }
      
      let feedbackData = {
        id: Date.now().toString(),
        title,
        description,
        tags: selectedTags,
        imageUrl,
        status: 'Submitted',
        votes: 0,
        createdAt: new Date(),
        createdBy: user ? user.uid : 'anonymous',
        upvotedBy: []
      };

      if (boardId === '0GxGJqQvjJRirMJMgRdn') {
        // For the specific board ID, add feedback to local state
        setLocalFeedbackItems(prevItems => [...prevItems, feedbackData]);
        setFeedbackItems(prevItems => [...prevItems, feedbackData]);
      } else if (user) {
        // For regular boards with logged-in users, add to Firestore
        const feedbackCollection = collection(firestore, 'boards', boardId, 'feedback');
        const docRef = await addDoc(feedbackCollection, feedbackData);
        console.log('Feedback submitted successfully:', docRef.id);
      } else {
        // For regular boards with non-logged-in users, store pending feedback
        onLoginRequired();
        return;
      }

      // Reset form fields
      setTitle('');
      setDescription('');
      setSelectedTags([]);
      setImage(null);
      setShowSubmitForm(false);
      setError(null);
    } catch (error) {
      console.error("Error submitting feedback: ", error);
      setError(`Failed to submit feedback: ${error.message}`);
    }
  }, [title, description, selectedTags, image, user, boardId, onLoginRequired]);

  const handleVote = useCallback(async (id) => {
    if (boardId !== '0GxGJqQvjJRirMJMgRdn' && !user) {
      onLoginRequired(() => handleVote(id));
      return;
    }

    const updateVote = (items) => items.map(item => {
      if (item.id === id) {
        const hasUpvoted = item.upvotedBy.includes(user?.uid || 'anonymous');
        return {
          ...item,
          votes: hasUpvoted ? item.votes - 1 : item.votes + 1,
          upvotedBy: hasUpvoted
            ? item.upvotedBy.filter(uid => uid !== (user?.uid || 'anonymous'))
            : [...item.upvotedBy, user?.uid || 'anonymous'],
          hasUpvoted: !hasUpvoted
        };
      }
      return item;
    });

    if (boardId === '0GxGJqQvjJRirMJMgRdn') {
      setLocalFeedbackItems(updateVote);
      setFeedbackItems(updateVote);
    } else {
      // Existing Firestore logic for non-demo boards or admin users
      const feedbackRef = doc(firestore, 'boards', boardId, 'feedback', id);
      await runTransaction(firestore, async (transaction) => {
        const feedbackDoc = await transaction.get(feedbackRef);
        if (!feedbackDoc.exists()) {
          throw new Error("Feedback does not exist!");
        }
        const feedbackData = feedbackDoc.data();
        const hasUpvoted = feedbackData.upvotedBy.includes(user.uid);
        transaction.update(feedbackRef, {
          votes: hasUpvoted ? feedbackData.votes - 1 : feedbackData.votes + 1,
          upvotedBy: hasUpvoted
            ? arrayRemove(user.uid)
            : arrayUnion(user.uid)
        });
      });
    }
  }, [user, onLoginRequired, boardId]);

  const handleCardClick = useCallback((item) => {
    setSelectedFeedback(item);
  }, []);

  const handleCloseModal = useCallback(() => {
    setSelectedFeedback(null);
    setShowSubmitForm(false);
  }, [setShowSubmitForm]);

  const onDragEnd = useCallback(async (result) => {
    console.log('onDragEnd called', { result, isAdmin, user: user?.uid });
    if (!result.destination || (!user && !isDemoBoard) || (!isAdmin && !isDemoBoard)) {
      console.log('Drag aborted:', { noDestination: !result.destination, noUser: !user, notAdmin: !isAdmin });
      return;
    }
  
    const { source, destination, draggableId } = result;

    if (source.droppableId === destination.droppableId) {
      console.log('Drag within same column, no action needed');
      return;
    }

    if (isDemoBoard && !isAdmin) {
      // Update local state for demo board non-admin users
      setLocalFeedbackItems(prevItems => prevItems.map(item => {
        if (item.id === draggableId) {
          return { ...item, status: destination.droppableId };
        }
        return item;
      }));
    } else {
      try {
        console.log('Updating feedback status', { draggableId, newStatus: destination.droppableId });
        const feedbackRef = doc(firestore, 'boards', boardId, 'feedback', draggableId);
        await updateDoc(feedbackRef, { status: destination.droppableId });
        console.log('Feedback status updated successfully');
        setError(null);
      } catch (error) {
        console.error("Error updating feedback status: ", error);
        setError("Failed to update feedback status. Please try again.");
      }
    }
  }, [user, isAdmin, boardId, isDemoBoard]);

  const handleEditFeedback = useCallback((id) => {
    const feedbackToEdit = feedbackItems.find(item => item.id === id);
    if (feedbackToEdit) {
      setSelectedFeedback(feedbackToEdit);
      setTitle(feedbackToEdit.title);
      setDescription(feedbackToEdit.description);
      setSelectedTags(feedbackToEdit.tags || []);
      setSelectedStatus(feedbackToEdit.status || 'Submitted');
      setShowEditForm(true);
    }
  }, [feedbackItems]);

  const handleEditSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (!user || !selectedFeedback) return;

    try {
      const updatedFeedback = {
        title,
        description,
        tags: selectedTags,
        status: selectedStatus,
      };

      if (isDemoBoard && !isAdmin) {
        setLocalFeedbackItems(prevItems => prevItems.map(item => 
          item.id === selectedFeedback.id ? { ...item, ...updatedFeedback } : item
        ));
      } else {
        const feedbackRef = doc(firestore, 'boards', boardId, 'feedback', selectedFeedback.id);
        await updateDoc(feedbackRef, updatedFeedback);
      }

      setShowEditForm(false);
      setSelectedFeedback(null);
      setTitle('');
      setDescription('');
      setSelectedTags([]);
      setSelectedStatus('Submitted');
      setError(null);
    } catch (error) {
      console.error("Error updating feedback: ", error);
      setError("Failed to update feedback. Please try again.");
    }
  }, [user, selectedFeedback, title, description, selectedTags, selectedStatus, boardId, isDemoBoard, isAdmin]);

  const handleDeleteFeedback = useCallback(async (id) => {
    if (window.confirm('Are you sure you want to delete this feedback?')) {
      try {
        if (isDemoBoard && !isAdmin) {
          setLocalFeedbackItems(prevItems => prevItems.filter(item => item.id !== id));
        } else {
          const feedbackRef = doc(firestore, 'boards', boardId, 'feedback', id);
          await deleteDoc(feedbackRef);
        }
        setSelectedFeedback(null);
        setError(null);
      } catch (error) {
        console.error('Error deleting feedback:', error);
        setError('Failed to delete feedback. Please try again.');
      }
    }
  }, [boardId, isDemoBoard, isAdmin]);

  const getSortedAndFilteredFeedback = useCallback((items) => {
    return items
      .filter(item => filterStatus === 'All' || item.status === filterStatus)
      .sort((a, b) => {
        if (sortBy === 'votes') {
          return b.votes - a.votes;
        } else {
          return b.createdAt - a.createdAt;
        }
      });
  }, [filterStatus, sortBy]);

  const memoizedFeedbackItems = useMemo(() => {
    console.log('Calculating memoizedFeedbackItems with statuses:', statuses);
    console.log('Current feedbackByStatus:', feedbackByStatus);
    const result = statuses
      .filter(status => status !== 'Submitted')
      .map(status => {
        const items = getSortedAndFilteredFeedback(feedbackByStatus[status] || []);
        console.log(`Items for status ${status}:`, items);
        return { status, items };
      });
    console.log('Resulting memoizedFeedbackItems:', result);
    return result;
  }, [statuses, feedbackByStatus, getSortedAndFilteredFeedback]);

  const submittedFeedback = useMemo(() => {
    return getSortedAndFilteredFeedback(feedbackByStatus['Submitted'] || []);
  }, [feedbackByStatus, getSortedAndFilteredFeedback]);

  useEffect(() => {
    console.log('Current memoizedFeedbackItems:', memoizedFeedbackItems);
  }, [memoizedFeedbackItems]);

  // Add this useEffect to log statuses whenever they change
  useEffect(() => {
    console.log('Current statuses:', statuses);
  }, [statuses]);

  if (loading) {
    return <div>Loading board...</div>;
  }

  if (!board) {
    return <div>Board not found</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-7xl">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <strong className="font-bold">Error:</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      )}
      <h1 className="text-3xl font-semibold mb-2 text-center" style={{ color: theme.primary }}>
        {board.name}
      </h1>
      {board.description && (
        <p className="text-center mb-8 text-gray-600">{board.description}</p>
      )}
      
      {/* Controls Section */}
      <div className="mb-8 flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
        <div className="flex space-x-4">
          <Select value={sortBy} onValueChange={(value) => setSortBy(value)}>
            <SelectTrigger className="w-[140px]">
              <SelectValue placeholder="Sort by" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="date">Date</SelectItem>
              <SelectItem value="votes">Votes</SelectItem>
            </SelectContent>
          </Select>
  
          <Select value={filterStatus} onValueChange={(value) => setFilterStatus(value)}>
            <SelectTrigger className="w-[140px]">
              <SelectValue placeholder="Filter by status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="All">All</SelectItem>
              {statuses.map(status => (
                <SelectItem key={status} value={status}>{status}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
  
      {/* Kanban Board */}
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 overflow-x-auto pb-4">
          {memoizedFeedbackItems.map(({ status, items }) => (
            <Droppable key={status} droppableId={status}>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="flex-shrink-0 w-full md:w-80 bg-gray-50 p-4 rounded-lg shadow-md border border-gray-200 kanban-column"
                >
                  <h2 className="font-bold mb-4 text-lg flex items-center justify-between" style={{ color: theme.secondary }}>
                    <span>{status}</span>
                    <Badge variant="secondary">{items.length}</Badge>
                  </h2>
                  {items.map((item, index) => (
                    <Draggable 
                      key={item.id} 
                      draggableId={item.id} 
                      index={index} 
                      isDragDisabled={!isAdmin}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`mb-2 ${snapshot.isDragging ? 'shadow-lg' : 'shadow-md'} transition-shadow duration-200`}
                          onClick={() => handleCardClick(item)}
                        >
                          <Card className="w-full h-[180px] flex flex-col p-3">
                            <div className="flex justify-between items-start mb-1">
                              <CardTitle className="text-base font-semibold line-clamp-2 pr-2" style={{maxWidth: '75%'}}>
                                {item.title}
                              </CardTitle>
                              {item.tags && item.tags.length > 0 && (
                                <Badge variant="outline" className="text-xs px-1 py-0 whitespace-nowrap">
                                  {item.tags[0]}
                                </Badge>
                              )}
                            </div>
                            <CardDescription className="text-xs text-gray-500 line-clamp-2 mb-2">
                              {item.description}
                            </CardDescription>
                            <div className="mt-auto flex justify-between items-center text-xs">
                              <Button 
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleVote(item.id);
                                }}
                                variant={item.hasUpvoted ? "default" : "outline"}
                                size="sm"
                                className={`h-6 px-2 py-0 text-xs ${item.hasUpvoted ? 'bg-primary text-primary-foreground' : 'bg-background text-foreground'}`}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-1"><path d="M12 19V5M5 12l7-7 7 7"/></svg>
                                {item.votes}
                              </Button>
                              <span className="text-gray-500">
                                {item.createdAt && item.createdAt.toDate ? format(item.createdAt.toDate(), 'PP') : 'Date unknown'}
                              </span>
                            </div>
                          </Card>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </DragDropContext>
  
      {/* Submit New Feedback Button */}
      <div className="mt-8 mb-4">
        <Button 
          onClick={() => setShowSubmitForm(!showSubmitForm)}
          variant="outline"
        >
          {showSubmitForm ? 'Hide Feedback Form' : 'Submit New Feedback'}
        </Button>
      </div>
  
      {/* Feedback Form */}
      <Dialog open={showSubmitForm} onOpenChange={setShowSubmitForm}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Submit Feedback</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit}>
            <div className="space-y-4">
              <Input
                id="title"
                placeholder="Enter title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
              <Textarea
                id="description"
                placeholder="Enter description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
              <div className="flex flex-wrap gap-2">
                {tags.map(tag => (
                  <label key={tag} className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      value={tag}
                      checked={selectedTags.includes(tag)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedTags([...selectedTags, tag]);
                        } else {
                          setSelectedTags(selectedTags.filter(t => t !== tag));
                        }
                      }}
                    />
                    <span className="ml-2">{tag}</span>
                  </label>
                ))}
              </div>
              {(isAdmin || user?.uid === board?.createdBy) && (
                <Select
                  value={selectedStatus}
                  onValueChange={(value) => setSelectedStatus(value)}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select status" />
                  </SelectTrigger>
                  <SelectContent>
                    {statuses.map(status => (
                      <SelectItem key={status} value={status}>{status}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
              <Input
                id="image"
                type="file"
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </div>
            <DialogFooter>
              <Button type="submit">Submit Feedback</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
  
      {/* Submitted Feedback Section */}
      <div className="mt-8">
        <h2 className="text-2xl font-semibold mb-4">Submitted Feedback</h2>
        <div className="space-y-4">
          {submittedFeedback.map(item => (
            <Card key={item.id} className="w-full p-4" onClick={() => handleCardClick(item)}>
              <CardTitle className="text-lg font-semibold mb-2">{item.title}</CardTitle>
              <CardDescription className="text-sm text-gray-500 mb-2">{item.description}</CardDescription>
              <div className="flex justify-between items-center text-xs">
                <Button 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleVote(item.id);
                  }}
                  variant={item.hasUpvoted ? "default" : "outline"}
                  size="sm"
                  className={`h-6 px-2 py-0 text-xs ${item.hasUpvoted ? 'bg-primary text-primary-foreground' : 'bg-background text-foreground'}`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-1"><path d="M12 19V5M5 12l7-7 7 7"/></svg>
                  {item.votes}
                </Button>
                <span className="text-gray-500">
                  {item.createdAt && item.createdAt.toDate ? format(item.createdAt.toDate(), 'PP') : 'Date unknown'}
                </span>
              </div>
            </Card>
          ))}
        </div>
      </div>
  
      {/* Feedback Detail Modal */}
      <Dialog open={!!selectedFeedback} onOpenChange={handleCloseModal}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{selectedFeedback?.title}</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <p>{selectedFeedback?.description}</p>
            <div className="flex flex-wrap gap-2">
              {selectedFeedback?.tags.map(tag => (
                <Badge key={tag} variant="secondary">{tag}</Badge>
              ))}
            </div>
            <p className="text-sm text-gray-500">
              Status: {selectedFeedback?.status}
            </p>
            <p className="text-sm text-gray-500">
              Created on: {selectedFeedback?.createdAt ? format(selectedFeedback.createdAt.toDate(), 'PPpp') : ''}
            </p>
            {(isAdmin || user?.uid === selectedFeedback?.createdBy) && (
              <p className="text-sm text-gray-500">
                Author ID: {selectedFeedback?.createdBy}
              </p>
            )}
          </div>
          <DialogFooter className="sm:justify-start">
            <Button
              onClick={() => handleVote(selectedFeedback.id)}
              variant={selectedFeedback?.hasUpvoted ? "default" : "outline"}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-1"><path d="M12 19V5M5 12l7-7 7 7"/></svg>
              {selectedFeedback?.votes}
            </Button>
            {(isAdmin || user?.uid === selectedFeedback?.createdBy) && (
              <>
                <Button onClick={() => handleEditFeedback(selectedFeedback.id)} variant="outline">
                  Edit
                </Button>
                <Button onClick={() => handleDeleteFeedback(selectedFeedback.id)} variant="destructive">
                  Delete
                </Button>
              </>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Edit Feedback Dialog */}
      <Dialog open={showEditForm} onOpenChange={setShowEditForm}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Edit Feedback</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleEditSubmit}>
            <div className="space-y-4">
              <Input
                id="edit-title"
                placeholder="Enter title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
              <Textarea
                id="edit-description"
                placeholder="Enter description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
              <div className="flex flex-wrap gap-2">
                {tags.map(tag => (
                  <label key={tag} className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      value={tag}
                      checked={selectedTags.includes(tag)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedTags([...selectedTags, tag]);
                        } else {
                          setSelectedTags(selectedTags.filter(t => t !== tag));
                        }
                      }}
                    />
                    <span className="ml-2">{tag}</span>
                  </label>
                ))}
              </div>
              {(isAdmin || user?.uid === board?.createdBy) && (
                <Select
                  value={selectedStatus}
                  onValueChange={(value) => setSelectedStatus(value)}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select status" />
                  </SelectTrigger>
                  <SelectContent>
                    {statuses.map(status => (
                      <SelectItem key={status} value={status}>{status}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            </div>
            <DialogFooter>
              <Button type="submit">Update Feedback</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default FeedbackBoard;