import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { firestore } from '../firebase';
import { doc, getDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Input } from './ui/input';
import { Button } from './ui/button';

const JoinBoard = () => {
  const [boardId, setBoardId] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { user, addBoardToUser } = useAuth();

  const handleJoinBoard = async (e) => {
    e.preventDefault();
    if (!user) {
      setError('You must be logged in to join a board');
      return;
    }

    try {
      const boardRef = doc(firestore, 'boards', boardId);
      const boardDoc = await getDoc(boardRef);

      if (!boardDoc.exists()) {
        setError('Board not found');
        return;
      }

      const boardData = boardDoc.data();
      if (boardData.members.includes(user.uid)) {
        setError('You are already a member of this board');
        return;
      }

      await updateDoc(boardRef, {
        members: arrayUnion(user.uid)
      });

      await addBoardToUser(boardId);
      navigate(`/board/${boardId}`);
    } catch (error) {
      console.error('Error joining board:', error);
      setError('Failed to join board. Please try again.');
    }
  };

  return (
    <div className="container mx-auto mt-10">
      <Card className="max-w-md mx-auto">
        <CardHeader>
          <CardTitle>Join Existing Board</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleJoinBoard}>
            <div className="space-y-4">
              <Input
                id="boardId"
                placeholder="Enter board ID"
                value={boardId}
                onChange={(e) => setBoardId(e.target.value)}
                required
              />
            </div>
            {error && <p className="text-red-500 mt-2">{error}</p>}
            <Button type="submit" className="mt-4">Join Board</Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default JoinBoard;